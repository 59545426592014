import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import router from './router/routes'
import store from './store'
import axios from "axios";

// import { StripePlugin } from '@vue-stripe/vue-stripe';


axios.defaults.baseURL = "https://travelbackend.dotlinkertech.com/" 
// axios.defaults.baseURL = "https://akashpaul.pythonanywhere.com/" 

// const options = {
//     // pk: process.env.STRIPE_PUBLISHABLE_KEY,
//     pk: 'pk_test_51PiZGrSEFjWlijvy5QPmcgPGR1lH9jKlLbU1Ca9Z0tnDDsWBjFvBXzrFopJNmCRJ1tZKqIolxjtCCn3yrex0HDov001lUvJq1L',
//     stripeAccount: process.env.STRIPE_ACCOUNT,
//     apiVersion: process.env.API_VERSION,
//     locale: process.env.LOCALE,
//   };

const head = createHead();
// createApp(App).use(store).use(router).use(StripePlugin, options).mount('#app')
createApp(App).use(store).use(head).use(router).mount('#app')

// "^2.7.16",
