// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/user';
// import router from "../../../router/routes";

const state = {
    // Additional state if needed
    getBlogs: '',
    getBlogsById: '',
}

const mutations = {
    SET_BLOGS(state, data) {
      state.getBlogs = data
    },
    SET_BLOG_BY_ID(state, data) {
      state.getBlogsById = data
    },
}

const actions = {
    // Additional actions if needed
      getBlogs({ commit }) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_BLOGS)
                    .then((res) => {
                        console.log('getBlogs---res !!', res)
                        if(Array.isArray(res.data)){
                            commit('SET_BLOGS', res.data.reverse())
                        }
                        else{
                           commit('SET_BLOGS', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getBlogs err', err)
                    })
            // })
    },

    getBlogById({ commit }, id) {
        console.log("id", id)
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_BLOGS_BY_ID + id)
                .then((res) => {
                    console.log('getBlogById---res !!', res)
                    commit('SET_BLOG_BY_ID', res.data[0])
                })
                .catch((err) => {
                    console.log('getBlogById err', err)
                })
        // })
    },

    addContact({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("name", payload.name);
        formData.append("email", payload.email);
        formData.append("subject", payload.subject);
        formData.append("message", payload.message);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_CONTACT, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("addContact res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                })
                .catch((err) => {
                    console.log("addContact err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    addNewsletter({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("email", payload.email);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_NEWSLETTER, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("addNewsletter res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                })
                .catch((err) => {
                    console.log("addNewsletter err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };