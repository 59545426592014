<template>
  <nav class="navbar navbar-expand-lg navbar-light pb-md-4 pb-3 fixed-top" style="background: #F6F4F0;">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand md:ml-0 ml-40 mr-30" style="width: 180px;">
        <img src="images/AEROMILES.ME (1).png" alt="logo icon">
      </router-link>
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-top: 17px;">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link px-md-3" :class="$route.path == '/' ? 'active' : ''" aria-current="page" @click="closeMenu">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/all-enquiries" class="nav-link px-md-3" :class="$route.path == '/all-enquiries' ? 'active' : ''" @click="closeMenu">Travel Quest</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/blogs" class="nav-link px-md-3" :class="$route.path == '/blogs' ? 'active' : ''" @click="closeMenu">Miles & Tales</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link px-md-3" :class="$route.path == '/about' ? 'active' : ''" @click="closeMenu">About Us</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link px-md-3" :class="$route.path == '/contact' ? 'active' : ''" @click="closeMenu">Contact</router-link>
          </li>
        </ul>
        <form class="d-flex">
          <div v-if="!User" class="d-md-flex items-center mr-20">
            <router-link to="login-user" class="button p-3 fw-400 text-14 border-dark-4 -blue-1 h-50 text-dark-4 new-green ml-20">Sign In / Register</router-link>
          </div>
          <div v-if="User">
              <div class="dropdown">
                <button class="dropdown-toggle mm" type="button" data-toggle="dropdown" aria-expanded="false">
                  <img v-if="User?.avatar != null" :src="User?.avatar"  class="small-userpic" alt="">
                  <img v-if="User?.avatar == null && User?.title === 'Mr'" src="images/user-demo.png" class="small-userpic" alt="">
                  <img v-if="User?.avatar == null && ['Mrs', 'Miss', 'Ms'].includes(User?.title)" src="images/woman-user.svg" class="small-userpic" alt="">
                  <span v-if="hasUnreadNotifications" class="dot"></span>
                </button>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/profile" @click="closeMenu"><i class="fa-solid fa-user"></i> My Profile</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-enquiries" @click="closeMenu">
                    <div class="d-flex align-items-center">
                      <span><i class="fa-solid fa-plane"></i> My Travel Quest</span>
                      <span v-if="hasUnreadNotifications" class="dot2 d-block"></span>
                    </div>
                  </router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-response" @click="closeMenu"><i class="fa-brands fa-resolving"></i> My Responses</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-transactions" @click="closeMenu"><i class="fa-solid fa-money-bill-transfer"></i> My Transactions</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="#" @click="logout()">
                    <span><i class="fa-solid fa-arrow-right-from-bracket"></i> Logout</span>
                    <loader v-if="loader" />
                  </router-link>
                </div>
              </div>
          </div>
        </form>
      </div>
    </div>
  </nav>
</template>
  
  <script>
  import loader from '../loader.vue';
  import { mapState } from "vuex";

  export default {
    name: 'HeaderPage',
    components: {
      loader
    },

    data(){
        return{
          loader: false,
        }
    },

    watch: {
      // Watch for route changes
      $route() {
        if (this.User) {
          this.fetchNotifications();
        }
      }
    },

    created() {
      if (this.User) {
        this.fetchNotifications();
      }
      document.addEventListener('click', this.handleOutsideClick);
      window.removeEventListener('scroll', this.handleScroll);
    },

    beforeUnmount() {
      document.removeEventListener('click', this.handleOutsideClick);
      window.removeEventListener('scroll', this.handleScroll);
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      },
      ...mapState({
        getNotifications: (state) => state.Enquiries.getNotifications,
      }),

      hasUnreadNotifications() {
        return Array.isArray(this.getNotifications) && this.getNotifications.some(
          (notif) => notif.read_at === null && notif.notifiable_id === this.User?.id
        );
      },
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'user',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{
            console.log("err", err);
            this.loader = false;
        })
      },

      closeMenu() {
        const menu = document.getElementById('navbarSupportedContent');
        if (menu.classList.contains('show')) {
          menu.classList.remove('show');
        }
      },

      handleOutsideClick(event) {
        const menu = document.getElementById('navbarSupportedContent');
        const toggleButton = document.querySelector('.navbar-toggler');

        // Check if the click is outside the menu and toggle button
        if (menu.classList.contains('show') && !menu.contains(event.target) && !toggleButton.contains(event.target)) {
          this.closeMenu();
        }
      },

      handleScroll() {
        this.closeMenu(); // Close menu on scroll
      },


      fetchNotifications() {
        this.$store
          .dispatch('getNotifications', this.User.id)
          .catch((err) => {
            console.error('Failed to fetch notifications:', err);
          });
      },
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .header{
    background-color: unset;
  }
  .small-userpic{
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .navbar .navbar-nav .nav-item .nav-link{
    font-family: 'poppins', sans-serif;
    color: black;
  }
  .active{
    color: #0C6980 !important;
    font-weight: 600 !important;
  }
  .fa-solid, .fas, .fa-brands {
    width: 21px;
  }
  .dropdown-menu{
    left: -90px;
    border: none;
    min-width: 10.8rem;
  }
  .mm{
    position: relative;
  }
  .mm .dot{
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
    top: 3px;
    right: 16px;
  }
  .dot2{
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
  }
  @media screen and (max-width: 1000px) {
    .dropdown-menu{
      left: 0px;
    }
  }
  </style>
  